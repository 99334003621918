import { produce } from "immer"
import { GetState, SetState } from "zustand"
import { AddToastAction, StoreState } from "../types"

export const getId = (): string => Math.random().toString(36).slice(0, 5)

type CreateToastInput = {
  action: AddToastAction
  set: SetState<StoreState>
  get: GetState<StoreState>
}

export function createToast({ action, set, get }: CreateToastInput) {
  const id = getId()

  // Create  new toast
  set(
    produce(get(), (draft) => {
      draft.toasts.byId.push(id)
      draft.toasts.items[id] = {
        id,
        ...action.payload,
      }
    }),
  )
}
